import "@fontsource/nunito/300.css";
import "@fontsource/nunito/400.css";
import "@fontsource/nunito/700.css";

import { navigate } from "gatsby";
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import { ReactNode, useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";

import ChatLink from "../../../../../atoms/chat-link/ChatLink";
import Icons from "../../../../../core/ui/images/icomoon/symbol-defs.svg";
import Footer from "../../../../../organisms/footer/AlternativeFooter";
import Meta from "../../../../../organisms/meta/meta";
import Navigation from "../../../../../organisms/navigation/Funnel";
import PageWrapper from "../../../../../organisms/page-wrapper/PageWrapper";
import { currentResultUuidState } from "../../../../../organisms/result/state/atoms";
import {
  useIdentifyProperties,
  useResult,
  useResultUuid,
  useUpdateResultLastUtmParams,
} from "../../../../../organisms/result/use-cases/result-use-cases";
import { PageId } from "../../../../../settings/pages";
import { identify } from "../../../../../utils/analytics";
import { getCurrentURLSearchParams } from "../../../../../utils/browser-features";
import useAxeptioScriptLoader from "../../../../../utils/hooks/useAxeptioScriptLoader";
import {
  getLocaleByCountry,
  shouldShowChatLink,
} from "../../../../../utils/locale-configuration-utils";
import { useUpdateResultTrackingParams } from "../../../application/result-use-cases";

interface FunnelLayoutProps {
  params: {
    resultUuid?: string;
  };
  title?: string;
  pageClass?: string;
  pageId: PageId;
  children: ReactNode;
  followResultRedirect?: boolean;
}

const FunnelLayout = ({
  params,
  title,
  pageClass,
  pageId,
  children,
  followResultRedirect = true,
}: FunnelLayoutProps): JSX.Element => {
  const setCurrentResultUuid = useSetRecoilState(currentResultUuidState);
  const { t } = useTranslation();
  const updateResultLastUtmParams = useUpdateResultLastUtmParams();
  const updateResultTrackingParams = useUpdateResultTrackingParams();
  const [langAttribute, setLangAttribute] = useState("");

  useAxeptioScriptLoader(langAttribute);

  useEffect(() => {
    void updateResultLastUtmParams();
  }, [updateResultLastUtmParams]);

  useEffect(() => {
    void updateResultTrackingParams();
  }, [updateResultTrackingParams]);

  useEffect(() => {
    params.resultUuid && setCurrentResultUuid(params.resultUuid);
  }, [params.resultUuid, setCurrentResultUuid]);

  const identifyProperties = useIdentifyProperties();
  const result = useResult();
  const resultUuid = useResultUuid();
  const { i18n } = useI18next();
  const showChatLink = result && shouldShowChatLink(result.country, pageId);

  if (followResultRedirect && result && resultUuid && resultUuid !== result.uuid) {
    const urlSearchParams = getCurrentURLSearchParams();

    void navigate(`/results/${result.uuid}/requoting/?${urlSearchParams}`);
  }

  useEffect(() => {
    if (!result?.country) {
      return;
    }

    const localeByCountry = getLocaleByCountry(result.country);
    if (localeByCountry !== i18n.language) {
      void i18n.changeLanguage(localeByCountry);
    }

    setLangAttribute(localeByCountry);
  }, [result?.country, i18n]);

  useEffect(() => {
    if (!identifyProperties) {
      return;
    }

    identify(identifyProperties.user_id, identifyProperties.traits);
  }, [identifyProperties]);

  return (
    <>
      {result && (
        <PageWrapper>
          <Meta
            title={title ?? t("common.page.title")}
            lang={langAttribute}
            bodyClass={pageClass}
            description={undefined}
            alternateVersions={false}
            slug={undefined}
            disableTranslations={true}
          />
          <Navigation countryCodeIso={result.country} email={result?.email} />
          <main id="main">{children}</main>
          <Footer />
          {showChatLink && <ChatLink countryCodeIso={result.country} />}
          <Icons />
        </PageWrapper>
      )}
    </>
  );
};

export default FunnelLayout;
